import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useCitySelect from 'shared/hooks/useCitySelect';

export default function Cities() {
  const { t } = useTranslation('homepage');
  const { handleSelectCity } = useCitySelect();

  return (
    <Box maxWidth={724} m="auto">
      <Title>{t('cities.title')}</Title>
      <Wrapper>
        <Pill onClick={() => handleSelectCity({ value: 'muenchen' })}>
          <Typography>{t('cities.munich')}</Typography>
        </Pill>
        <Pill onClick={() => handleSelectCity({ value: 'berlin' })}>
          <Typography>{t('cities.berlin')}</Typography>
        </Pill>
        <Pill onClick={() => handleSelectCity({ value: 'frankfurt-am-main' })}>
          <Typography>{t('cities.frankfurt')}</Typography>
        </Pill>
        <Pill onClick={() => handleSelectCity({ value: 'hamburg' })}>
          <Typography>{t('cities.hamburg')}</Typography>
        </Pill>
        <Pill onClick={() => handleSelectCity({ value: 'duesseldorf' })}>
          <Typography>{t('cities.duesseldorf')}</Typography>
        </Pill>
        <Pill onClick={() => handleSelectCity({ value: 'koeln' })}>
          <Typography>{t('cities.cologne')}</Typography>
        </Pill>
        <Pill onClick={() => handleSelectCity({ value: 'stuttgart' })}>
          <Typography>{t('cities.stuttgart')}</Typography>
        </Pill>
        <Pill onClick={() => handleSelectCity({ value: 'other' })}>
          <Typography>{t('cities.other')}</Typography>
        </Pill>
      </Wrapper>
    </Box>
  );
}

const Pill = styled.div`
  cursor: pointer;
  border-radius: 33px;
  padding: ${({ theme }) => theme.spacing(1.75, 2)};
  background: #f0f0f0;
  border: 1px solid #f0f0f0;
  &:hover {
    background: #fff;
    border: 1px solid #3c4340;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.4px;
    line-height: 17px;
    white-space: nowrap;
  }
`;

const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.88px;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)}px;
  flex-wrap: wrap;
  margin-top: 24px;
  justify-content: center;
`;
